

const lisBody = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque malesuada risus sed libero vehicula tempor at a odio. Integer consequat, sem nec cursus semper, velit eros consectetur odio, eget commodo libero turpis a sem. Cras condimentum nulla nulla, varius mattis lacus consectetur quis. Etiam cursus odio id purus ullamcorper pulvinar. Quisque ultrices mattis scelerisque. Fusce ac justo nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent scelerisque tempus hendrerit. Nam sagittis elit a lectus viverra convallis. Integer sed tortor nec nunc varius fermentum. Vivamus quis sollicitudin nisi. Nam venenatis blandit eros, feugiat eleifend lectus iaculis id. Pellentesque a vulputate ex. Curabitur dignissim ultricies odio, ut sagittis tellus efficitur at.';

const oneBody = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque malesuada risus sed libero vehicula tempor at a odio. Integer consequat, sem nec cursus semper, velit eros consectetur odio, eget commodo libero turpis a sem. Cras condimentum nulla nulla, varius mattis lacus consectetur quis. Etiam cursus odio id purus ullamcorper pulvinar. Quisque ultrices mattis scelerisque. Fusce ac justo nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent scelerisque tempus hendrerit. Nam sagittis elit a lectus viverra convallis. Integer sed tortor nec nunc varius fermentum. Vivamus quis sollicitudin nisi. Nam venenatis blandit eros, feugiat eleifend lectus iaculis id. Pellentesque a vulputate ex. Curabitur dignissim ultricies odio, ut sagittis tellus efficitur at.';

const kriterBody = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque malesuada risus sed libero vehicula tempor at a odio. Integer consequat, sem nec cursus semper, velit eros consectetur odio, eget commodo libero turpis a sem. Cras condimentum nulla nulla, varius mattis lacus consectetur quis. Etiam cursus odio id purus ullamcorper pulvinar. Quisque ultrices mattis scelerisque. Fusce ac justo nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent scelerisque tempus hendrerit. Nam sagittis elit a lectus viverra convallis. Integer sed tortor nec nunc varius fermentum. Vivamus quis sollicitudin nisi. Nam venenatis blandit eros, feugiat eleifend lectus iaculis id. Pellentesque a vulputate ex. Curabitur dignissim ultricies odio, ut sagittis tellus efficitur at.';

export default [
    { 
        title: 'Adition Technologies GmbH', 
        location: 'Freiburg im Breisgau, Germany',
        logo: require('../assets/adition-logo.png'), 
        body: lisBody, 
        duration:'May 2021 - Current', 
        role:'Software Engineer', 
        keywords: [
        'Backend developer',
        'PHP',
        'Symfony',
        'Doctrine',
        'MySQL',
        'Docker',
        'Kubernetes'
        ] 
    },
    { 
        title: 'Land in Sicht AG', 
        location: 'Freiburg im Breisgau, Germany',
        logo: require('../assets/logo-lis.svg'), 
        body: lisBody, 
        duration:'January 2019 - April 2021', 
        role:'Software Engineer', 
        keywords: [
        'Fullstack developer',
        'PHP',
        'Laravel',
        'Vue.Js',
        'RESTful APIs',
        'Docker'
        ] 
    },
    { 
        title: 'Onesource, Consultoria Informática Lda', 
        location: 'Coimbra, Portugal',
        logo: require('../assets/onesource-logo.jpg'),
        body: oneBody, 
        duration:'March 2017 - December 2018', 
        role:'Software Engineer', 
        keywords: [
            'Fullstack developer',
            'PHP',
            'JAVA',
            'Python',
            'Laravel',
            'Vue.Js',
            'RESTful APIs',
        ] 
    },
    { 
        title: 'KriterAdvance Unipessoal',
        location: 'Marinha Grande, Portugal', 
        logo: require('../assets/kriter-logo.png'),
        body: kriterBody, 
        duration:'Januray 2009 - September 2012', 
        role:'Managing Partner',
        keywords: [ 
            'Management',
            'Client contact and prospection',
        ]  
    },
];
