<template>
    <v-card
       
    >
        <v-list-item three-line>
            <v-list-item-avatar rounded='0'>
                <v-list-item-title>
                    <v-img :src="content.logo" contain class="pa-2"></v-img>
                </v-list-item-title>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="text-h5">
                 {{content.title}}
                </v-list-item-title>
                <v-list-item-subtitle>{{content.duration}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-subtitle class="mt-2 pb-0">The role:</v-card-subtitle>
        <v-card-text class="text--primary">
            {{content.role}}
        </v-card-text>
        <v-card-subtitle class="mt-2 pb-0">Location:</v-card-subtitle>
        <v-card-text class="text--primary">
            {{content.location}}
        </v-card-text>
        <v-card-subtitle class="pb-0">Keywords:</v-card-subtitle>
        <v-card-text class="text--primary">
            <ul>
                <li v-for="(keyword, index) in content.keywords" :key="index">
                    {{keyword}}
                </li>

            </ul>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'ContentCardComponent',
    props:{
        value: { required:true, type: Object }
    },
    data: () => ({
        content: null,
    }),
  created () {
      this.content = this.value;
  }
};
</script>

<style scoped>
    img {
        width:  75px;
        height: 75px;
        object-fit: contain;
    }
    .duration{
        font-size: medium;
    }
</style>
