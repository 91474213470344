import { render, staticRenderFns } from "./PortfolioComponent.vue?vue&type=template&id=c86d86ca&scoped=true&"
import script from "./PortfolioComponent.vue?vue&type=script&lang=js&"
export * from "./PortfolioComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c86d86ca",
  null
  
)

export default component.exports