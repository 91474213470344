<template>
  <v-app>
    <v-main transition="slide-x-transition">
      <v-container>
          <app-navigation-component />
          <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import AppNavigationComponent from './components/AppNavigationComponent';
  export default {
    name: 'App',
    components: {
      AppNavigationComponent,
    }
  };
</script>

<style>
</style>
