<template>
    <div>
        <span>{{value.title}}</span>
        <v-rating
            :value="value.rating"
            length="5"
            empty-icon="mdi-circle-outline"
            full-icon="mdi-circle"
            half-icon="mdi-circle-half-full"
            :readonly="true"
            :size="26"
            :dense="true"
            color="blue-grey lighten-3"
            background-color="grey lighten-1"
        />
    </div>
</template>

<script>
export default {
    name: 'RatedSkillComponent',
    props:{
        value: { type: Object, required: true }
    }
};
</script>

<style scoped>

</style>
