<template>
    <div>
        <v-tabs
            mobile-breakpoint="sm"
            border="top"
            centered
            
        >
            <v-tab
                :href="'#tab-professional'"
            >
                 <i class="fas fa-stream pa-2"></i>
                Professional Experience
            </v-tab>
            <v-tab
                :href="'#tab-skills'"
            >
                <i class="fas fa-cog pa-2"></i>
                Skills
            </v-tab>
            <v-tab-item
                :value="'tab-professional'"
            >  
                <v-row dense>
                    <v-col
                        v-for="(experience, index) in experiences" :key="index"
                    >
                        <content-card
                            class="content-card"
                            :value="experience"
                            @click.native="openModal(experience)"
                        />
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item
                :value="'tab-skills'"
            >
                <skill-component/>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import ContentCard from './ContentCardComponent';
import SkillComponent from './SkillComponent';
import PortfolioComponent from './PortfolioComponent';
import experiences from '../assets/content.js'
export default {
    name: 'SubNavigationBarComponent',
    components: { PortfolioComponent, SkillComponent, ContentCard },
    data: () => ({
        detailModal: false,
        projects: [],
        experience: null,
        experiences: experiences
    }),
};
</script>

<style scoped>
    .content-card {
        height:100%
    }
    .content-card:hover{
        cursor: pointer;
    }
  
</style>
