<template>
  <v-container>
    <sub-navigation-bar />
  </v-container>
</template>

<script>

import SubNavigationBar from '../components/SubNavigationBarComponent';
export default {
  name: 'home',
  components: { SubNavigationBar },
}
</script>
