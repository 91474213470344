<template>
    <v-container fluid>
        <h1>Skills</h1>
        <v-row>
            <v-col cols="sm">
                <h3>Programming Languages</h3>
                <RatedSkill
                   v-for="(skill, index) in programmingSkills"
                   :value="skill"
                   :key="'programming-' + index"
                />
            </v-col>
            <v-col>
                <h3>Frameworks</h3>
                <RatedSkill
                    v-for="(skill, index) in frameworkSkills"
                    :value="skill"
                    :key="'framework-' + index"
                />
            </v-col>
            <v-col>
                <h3>Stack</h3>
                <ul>
                    <li>Git</li>
                    <li>Jenkins</li>
                    <li>Docker</li>
                    <li>Kubernetes</li>
                </ul>
            </v-col>
            <v-col>
                <h3>Languages</h3>
                <RatedSkill
                    v-for="(skill, index) in languageSkills"
                    :value="skill"
                    :key="'language-' + index"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import RatedSkill from './RatedSkillComponent';
export default {
    name: 'SkillComponent',
    components: { RatedSkill },
    data: () => ({
        programmingSkills: [
            { title: 'PHP', rating:4 } ,
            { title: 'Javascript', rating:4 } ,
            { title: 'Python', rating:3 } ,
            { title: 'Java SE', rating:2 } ,
            { title: 'C', rating:2 } ,
            { title: 'GO', rating:2 } ,
        ],
        frameworkSkills: [
            { title: 'Laravel', rating:4 } ,
            { title: 'VueJS', rating:3 } ,
        ],
        languageSkills: [
            { title: 'Portuguese', rating:5 } ,
            { title: 'English', rating:5 } ,
            { title: 'German', rating:1 } ,
        ],
    }),
};
</script>

<style scoped>
    ul{
        list-style: none;
    }
</style>
