<template>
    <v-container>
        <v-row name="information">
            <v-col>
                <div class="d-flex flex-row">
                    <div

                    >
                        <img style="width: 150px; border-radius:50%" alt="André Oliveira" src="../assets/profile.jpg" />
                    </div>
                    <div class="ma-4 d-flex flex-column flex-grow-1">                
                        <h3>
                            André Oliveira
                        </h3>
                        <span>
                            Sofware Developer
                        </span>
        
                    </div>
                </div>
            </v-col>
            <v-col name="personal-information">
                <div class="mt-4">
                    <p>
                        I am nothing<br>
                        I shall never be anything<br>
                        I cannot want to be anything.<br>
                        Aside from that, I have within me all the dreams of the world.<br>
                    </p>
                    <p class="credit-p">
                        Tabacaria by Álvaro de Campos
                    </p>
                </div>
            </v-col>
            <v-col>
                <v-row>
                    <div class="d-flex flex-column mx-4" >
                        <strong>Connect with me</strong>
                    </div>
                </v-row>
                <v-row>
                    <div class="d-flex flex-row">
                        <div  
                            v-for="(contact, index) in contacts" 
                            :key="index"
                        >
                            <a role="button" class="social-button ma-4" :href="contact.url" target="_blank">
                                <i :class="contact.icon"></i>
                            </a>
                        </div>    
                    </div>
                </v-row>
                
            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
    export default {
        name: 'AppNavigationComponent',
        data(){
            return {
                contacts: [
                    { name: 'linkedin', url: 'https://www.linkedin.com/in/andre-oliveira-dev', icon: 'fab fa-linkedin fa-3x', message: 'Connect with me on LinkedIn' },
                    { name: 'xing', url: 'https://www.xing.com/profile/Andre_Oliveira22/cv', icon: 'fab fa-xing fa-3x', message: 'Connect with me on Xing' },
                    { name: 'github', url: 'https://github.com/AndreOliveiraRepos', icon: 'fab fa-github fa-3x', message: 'Check some of my code' },
                    { name: 'e-mail', url: 'mailto:andre.a.p.oliveira87@gmail.com', icon: 'fas fa-envelope fa-3x', message: 'Send me an E-mail' },
                ]
            }
        }
    };
</script>

<style scoped>
    .credit-p{
        font-style: italic;
    }
    a .social-button{
        color: red lighten-3!important;
    }
</style>
